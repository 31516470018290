<template>
  <div
    v-if="features"
    class="sb-features-section"
    :style="bg_color ? `background-color: ${bg_color}` : ''"
    :class="{ 'u-p-t-less': !has_padding_top }"
  >
    <div :class="{ 'inner-container fad': !isMd }">
      <div class="landing-download__flex">
        <SbFeatureBlock
          v-for="feature in features"
          :key="feature._uid"
          :feature="feature"
          :is-large="features.length <= 3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Mobile from '@/mixins/mobile-mixin'
import SbFeatureBlock from '@/components/Storyblok/SbFeatureBlock'

export default {
  name: 'SbFeaturesSection',
  components: {
    SbFeatureBlock,
  },
  mixins: [Mobile],
  props: {
    features: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    has_padding_top: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
.sb-features-section {
  padding: $spacing-xl $spacing-xl 0 $spacing-xl;
  .landing-download {
    &__flex {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      gap: $spacing-xs;
      padding-top: 40px;
      padding-bottom: 0;
      flex-wrap: wrap;
      &__item {
        flex: 0 0 10%;
        :not(:last-child) {
          padding-right: 20px;
          margin-right: 0;
        }
      }
      @include mq($mq-md) {
        flex-wrap: wrap;
        padding-bottom: 0;
        justify-content: flex-start;
        gap: $spacing-md;
      }
      &__item--large {
        @include mq($mq-md) {
          flex: 0 0 25%;
        }
        @include mq($mq-xs) {
          flex: 0 0 50%;
        }
      }
      &__item--small {
        @include mq($mq-md) {
          flex: 0 0 calc(31% - 20px / 2) !important;
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
